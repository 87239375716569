/* eslint-disable react/no-unescaped-entities, max-len */

import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import DefaultLayout from '../containers/default'
import Wrap from '../components/layout/wrap'

import { styles } from '../styles/globals'
import JawboneHealthLogo from '../svgs/jawbone-health-logo.svg'

const PressWrap = styled(Wrap)`
    padding: 5rem 0;
`

const Header = styled.header`
    background-color: ${styles.bannerBackground};
    text-align: center;
    font-size: 1.2rem;
    color: #fff;
`

const StyledJawboneHealthLogo = styled(JawboneHealthLogo)`
    width: 180px;
    height: 61px;
    margin: 4rem 0;
    fill: ${styles.colors.white};
`

const Paragraph = styled.p`
    margin: 2rem 0;
`

const PressLink = styled.a`
    color: #000;
    text-decoration: underline;
`
const AnnouncementList = styled.ul`
    list-style: none;
`

const AnnouncementItem = styled.li`
    margin: 1rem 0;
`

const announcements = []

// [
//     {
//         date: '01.01.2020',
//         text: 'Minim sausage boudin short loin.',
//         link: ''
//     }
// ]

export default () => (
    <DefaultLayout>
        <Helmet>
            <title>Jawbone Health Press</title>
            <meta name="description" content="Jawbone Health Press" />
        </Helmet>
        <Header>
            <StyledJawboneHealthLogo />
        </Header>
        <PressWrap>
            <h1>Press</h1>
            {announcements.length ? (
                <Paragraph>
                    <AnnouncementList>
                        {announcements.map((announcement, i) => (
                            <AnnouncementItem key={i}>
                                {`${announcement.date} | `}
                                <PressLink href={announcement.link}>
                                    {announcement.text}
                                </PressLink>
                            </AnnouncementItem>
                        ))}
                    </AnnouncementList>
                </Paragraph>
            ) : (
                <Paragraph>Coming soon.</Paragraph>
            )}
            <Paragraph>
                For Press Inquires please contact{' '}
                <PressLink href="mailto:press&#064;jawbonehealth.com">
                    press&#064;jawbonehealth.com
                </PressLink>
            </Paragraph>
        </PressWrap>
    </DefaultLayout>
)
